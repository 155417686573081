import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './App.css';

import Sandman from './pages/Sandman';
import Delirium from './pages/Delirium';
import Despair from './pages/Despair';
import Destruction from './pages/Destruction';
import Desire from './pages/Desire';
import Destiny from './pages/Destiny';
import Death from './pages/Death';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Death />
        </Route>
        <Route path="/destiny" exact>
          <Destiny />
        </Route>
        <Route path="/desire" exact>
          <Desire />
        </Route>
        <Route path="/destruction">
          <Destruction />
        </Route>
        <Route path="/despair">
          <Despair />
        </Route>
        <Route path="/delirium">
          <Delirium />
        </Route>
        <Route path="/sandman">
          <Sandman />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

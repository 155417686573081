import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { addToken, addNetwork } from '../utils/metamask';

import AudioPlayer from '../components/AudioPlayer';

import { ReactComponent as Book } from '../images/book.svg';
import { ReactComponent as GitHub } from '../images/github.svg';
import { ReactComponent as Medium } from '../images/medium.svg';
import { ReactComponent as Telegram } from '../images/telegram.svg';
import { ReactComponent as Twitter } from '../images/twitter.svg';
import { ReactComponent as Youtube } from '../images/youtube.svg';
import { ReactComponent as Polygon } from '../images/polygon.svg';
import auditSC from '../images/kyc-lowrisk-by-audit-sc.png';

import audioSrc from '../media/despair.mp3';

const Despair = () => {
  useEffect(() => {
    document.body.classList.add('despair-page')
    const link = document.querySelector("link[rel~='icon']")
    link.href = '/favicon-despair.ico'
  }, [])

  useEffect(() => {
    return () => {
      document.body.classList.remove('despair-page')
    }
  }, [])

  return (
    <div className="container">
      <div className="row">
        <aside className="col-lg-3 d-none d-lg-flex flex-lg-column vh-100 py-5 align-items-lg-start">
          <h2 className="d-none d-lg-block flex-grow-1">DESPAIR</h2>
          <a href="https://rugdoc.io/project/despair/" className="position-relative rugdoc" target="_blank" rel="noreferrer">
            <small className="position-absolute end-0 rounded text-light border border-warning px-1 neon">KYC / LOW RISK</small>
            <img src="https://rugdoc.io/assets/2021/06/rugdoc-review-badge-with-glow.png" width="200" />
          </a>
        </aside>
        <main role="main" className="col-lg-6 vh-100 pt-5 pb-2 text-center d-flex flex-column position-relative">
        <div className="position-absolute" style={{ top: '10px', left: '0', right: '0' }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="text-light me-2">go to: </span>
              <Link to="/sandman" className="btn btn-outline-danger me-1">L1</Link>
              <Link to="/delirium" className="btn btn-outline-warning me-1">L2</Link>
              <Link to="/despair" className="btn btn-outline-secondary disabled me-1">L3</Link>
              <Link to="/destruction" className="btn btn-outline-primary me-1">L4</Link>
              <Link to="/desire" className="btn btn-outline-danger me-1">L5</Link>
              <Link to="/destiny" className="btn btn-outline-light me-1">L6</Link>
              <Link to="/" className="btn btn-outline-primary">L7</Link>
            </div>
          </div>
          <div className="mx-3 mx-lg-5">
            <h1 className="d-lg-none">DESPAIR</h1>
          </div>
          <div className="mx-lg-9 mb-3 mt-lg-3 flex-grow-1">
            <div className="card border-secondary shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token btn btn-link stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="despair" onClick={addToken}>
                DESPAIR
              </a>
            </div>
            <div className="card border-secondary shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token btn btn-link stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="despairMatic" onClick={addToken}>
                DESPAIR-MATIC
              </a>
            </div>
            <div className="card border-secondary shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token btn btn-link stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="despairUsdc" onClick={addToken}>
                DESPAIR-USDC
              </a>
            </div>
            <div className="card border-secondary shadow-card mb-2 bg-transparent">
              <a href="#" className="stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" id="add-network" onClick={addNetwork}>
                <Polygon />
                <span className="ms-2">Add Polygon Network</span>
              </a>
            </div>
          </div>
          <footer className="mb-4 mb-lg-0">
            <AudioPlayer audioSrc={audioSrc} />
            <a className="link-light mx-2" href="https://github.com/sandmanfinance" target="_blank" rel="noreferrer">
              <GitHub />
            </a>
            <a className="link-light mx-2" href="https://docs.despair.sandman.finance/" target="_blank" rel="noreferrer">
              <Book />
            </a>
            <a className="link-light mx-2" href="https://twitter.com/sandman_finance" target="_blank" rel="noreferrer">
              <Twitter />
            </a>
            <a className="link-light mx-2" href="https://t.me/SandMan_Finance" target="_blank" rel="noreferrer">
              <Telegram />
            </a>
            <a className="link-light mx-2" href="https://sandmanfinance.medium.com/" target="_blank" rel="noreferrer">
              <Medium />
            </a>
            <a className="link-light mx-2" href="https://www.youtube.com/channel/UCN_deX0boZPVUre-SV6aqwA" target="_blank" rel="noreferrer">
              <Youtube />
            </a>
          </footer>
          <div className="d-flex d-lg-none justify-content-center">
            <a href="https://rugdoc.io/project/despair/" target="_blank" rel="noreferrer" className="card-link btn btn-sm btn-outline-light me-1">
              RugDoc<br />
              <small className="text-warning ms-1">KYC / LOW RISK</small>
            </a>
            {/* <a href="https://paladinsec.co/projects/despair-sandman-finance/" className="card-link btn btn-sm btn-outline-light" target="_blank" rel="noreferrer">
              PALADIN<br />
              <small className="text-warning">AUDITED</small>
            </a> */}
            <a href="https://audit.sc/audits/despair-project-audit-review/" className="card-link btn btn-sm btn-outline-light" target="_blank" rel="noreferrer">
              AUDIT.SC<br />
              <small className="text-warning">KYC / LOW RISK</small>
            </a>
          </div>
          <div className="text-light mt-2" style={{ backgroundColor: 'rgba(33, 33, 33, .33)' }}>Original Artwork</div>
        </main>
        <aside className="col-lg-3 d-none d-lg-flex flex-lg-column vh-100 py-5 align-items-lg-end">
          <div className="d-none d-lg-block flex-grow-1" />
          <div class="d-flex flex-column">
            {/* <a href="https://paladinsec.co/projects/despair-sandman-finance/" className="position-relative mb-1" target="_blank" rel="noreferrer">
              <img src="https://paladinsec.co/pld/assets/audited-by-paladin-long.svg" width="200" />
            </a> */}
            <a href="https://audit.sc/audits/despair-project-audit-review/" target="_blank" className="bsc-news">
              <img src={auditSC} width="200" />
            </a>
          </div>
        </aside>
      </div>
    </div>
  );
}

export default Despair;

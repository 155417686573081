import React, { useRef, useState, useEffect } from 'react'

const AudioPlayer = ({ audioSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const audioRef = useRef(new Audio(audioSrc))
  // audioRef.current.autoplay = true OPTIONAL
  audioRef.current.loop = true

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
  }, [isPlaying])

  return (
    <a key="music" href="#" role="button" className={`mx-2 ${isPlaying ? 'playing' : ''}`} onClick={(evt) => { evt.preventDefault(); setIsPlaying(!isPlaying); }}>
      <svg id="music-icon" width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="music" className="svg-inline--fa fa-music fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M470.38 1.51L150.41 96A32 32 0 0 0 128 126.51v261.41A139 139 0 0 0 96 384c-53 0-96 28.66-96 64s43 64 96 64 96-28.66 96-64V214.32l256-75v184.61a138.4 138.4 0 0 0-32-3.93c-53 0-96 28.66-96 64s43 64 96 64 96-28.65 96-64V32a32 32 0 0 0-41.62-30.49z" />
      </svg>
    </a>
  )
}

export default AudioPlayer

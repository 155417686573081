import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { addToken, addNetwork } from '../utils/metamask';

import AudioPlayer from '../components/AudioPlayer';

import { ReactComponent as Book } from '../images/book.svg';
import { ReactComponent as GitHub } from '../images/github.svg';
import { ReactComponent as Medium } from '../images/medium.svg';
import { ReactComponent as Telegram } from '../images/telegram.svg';
import { ReactComponent as Twitter } from '../images/twitter.svg';
import { ReactComponent as Youtube } from '../images/youtube.svg';
import { ReactComponent as Polygon } from '../images/polygon.svg';

import audioSrc from '../media/delirium.mp3';

const Delirium = () => {
  useEffect(() => {
    document.body.classList.add('delirium-page')
    const link = document.querySelector("link[rel~='icon']")
    link.href = '/favicon-delirium.ico'
  }, [])

  useEffect(() => {
    return () => {
      document.body.classList.remove('delirium-page')
    }
  }, [])

  return (
    <div className="container">
      <div className="row">
        <aside className="col-lg-3 d-none d-lg-flex flex-lg-column vh-100 py-5 align-items-lg-start">
          <h2 className="d-none d-lg-block flex-grow-1">DELI<span>RIUM</span></h2>
          <a href="https://rugdoc.io/project/delirium/" className="position-relative rugdoc" target="_blank" rel="noreferrer">
            {/* <small className="position-absolute end-0 rounded text-light border border-warning px-1 neon">KYC / LOW RISK</small> */}
            <small className="pending position-absolute top-0 end-0 rounded px-1">KYC/LOW RISK</small>
            <img src="https://rugdoc.io/assets/2021/06/rugdoc-review-badge-with-glow.png" width="200" />
          </a>
        </aside>
        <main role="main" className="col-lg-6 vh-100 py-5 text-center d-flex flex-column position-relative">
        <div className="position-absolute" style={{ top: '10px', left: '0', right: '0' }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="text-light me-2">go to:</span>
              <Link to="/sandman" className="btn btn-outline-danger me-1">L1</Link>
              <Link to="/delirium" className="btn btn-outline-secondary disabled me-1">L2</Link>
              <Link to="/despair" className="btn btn-outline-secondary me-1">L3</Link>
              <Link to="/destruction" className="btn btn-outline-primary me-1">L4</Link>
              <Link to="/desire" className="btn btn-outline-danger me-1">L5</Link>
              <Link to="/destiny" className="btn btn-outline-light me-1">L6</Link>
              <Link to="/" className="btn btn-outline-primary">L7</Link>
            </div>
          </div>
          <div className="mx-3 mx-lg-5 flex-grow-1">
            <h1 className="d-lg-none">DELI<span>RIUM</span></h1>
          </div>
          <div className="mx-lg-9 mb-3">
            <div className="card border-success shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token btn btn-link stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="delight" onClick={addToken}>
                DELIGHT
              </a>
            </div>
            <div className="card border-success shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token btn btn-link stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="delirium" onClick={addToken}>
                DELIRIUM
              </a>
            </div>
            <div className="card border-success shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token btn btn-link stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="deliriumMatic" onClick={addToken}>
                DELIRIUM-MATIC
              </a>
            </div>
            <div className="card border-success shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token btn btn-link stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="deliriumUsdc" onClick={addToken}>
                DELIRIUM-USDC
              </a>
            </div>
            <div className="card border-success shadow-card mb-2 bg-transparent">
              <a href="#" className="stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" id="add-network" onClick={addNetwork}>
                <Polygon />
                <span className="ms-2">Add Polygon Network</span>
              </a>
            </div>
          </div>
          <footer className="mb-4 mb-lg-0">
            <AudioPlayer audioSrc={audioSrc} />
            <a className="link-light mx-2" href="https://github.com/sandmanfinance" target="_blank" rel="noreferrer">
              <GitHub />
            </a>
            <a className="link-light mx-2" href="https://docs.delirium.sandman.finance/" target="_blank" rel="noreferrer">
              <Book />
            </a>
            <a className="link-light mx-2" href="https://twitter.com/sandman_finance" target="_blank" rel="noreferrer">
              <Twitter />
            </a>
            <a className="link-light mx-2" href="https://t.me/SandMan_Finance" target="_blank" rel="noreferrer">
              <Telegram />
            </a>
            <a className="link-light mx-2" href="https://sandmanfinance.medium.com/" target="_blank" rel="noreferrer">
              <Medium />
            </a>
            <a className="link-light mx-2" href="https://www.youtube.com/channel/UCN_deX0boZPVUre-SV6aqwA" target="_blank" rel="noreferrer">
              <Youtube />
            </a>
          </footer>
          <div className="d-flex d-lg-none justify-content-center">
            <a href="https://rugdoc.io/project/delirium/" target="_blank" rel="noreferrer" className="card-link btn btn-sm btn-outline-light me-1">
              RugDoc<br />
              <small className="text-warning ms-1">KYC/LOW RISK</small>
            </a>
            <a href="https://paladinsec.co/projects/delirium-sandman-finance/" className="card-link btn btn-sm btn-outline-light" target="_blank" rel="noreferrer">
              PALADIN<br />
              <small className="text-warning">AUDITED</small>
            </a>
            <a href="https://binancechain.news/bscn-audits/delirium-token-and-farm-smart-contract/" className="card-link btn btn-sm btn-outline-light" target="_blank" rel="noreferrer">
              BSCNEWS<br />
              <small className="text-warning">AUDITED</small>
            </a>
          </div>
        </main>
        <aside className="col-lg-3 d-none d-lg-flex flex-lg-column vh-100 py-5 align-items-lg-end">
          <h2 className="d-none d-lg-block flex-grow-1"><span>DELI</span>GHT</h2>
          <div class="d-flex flex-column">
            <a href="https://paladinsec.co/projects/delirium-sandman-finance/" className="position-relative mb-1" target="_blank" rel="noreferrer">
              <img src="https://paladinsec.co/pld/assets/audited-by-paladin-long.svg" width="200" />
            </a>
            <a href="https://binancechain.news/bscn-audits/delirium-token-and-farm-smart-contract/" target="_blank" className="bsc-news link-light">
              <img src="https://global-uploads.webflow.com/5fad86e1f5867747a5250efa/5fc78b9c51ddf6747725cb42_Logo-2.svg" width="54" height="29" />
              AUDITED
            </a>
          </div>
        </aside>
      </div>
    </div>
  );
}

export default Delirium;

import Web3 from 'web3'

import tokens from '../data/tokens.json'

export const addToken = (evt) => {
  evt.preventDefault();
  var target = evt.target;

  if (!window.ethereum) {
    alert('Please install metamask plugin in your browser');
    return;
  }

  window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokens[target.dataset.token].address,
        symbol: tokens[target.dataset.token].symbol,
        decimals: tokens[target.dataset.token].decimals,
        image: tokens[target.dataset.token].image,
      },
    },
  });
}

export const addNetwork = async (evt) => {
  evt.preventDefault();

  if (!window.ethereum) {
    alert('Please install metamask plugin in your browser');
    return;
  }

  const params = [{
      chainId: '0x89',
      chainName: 'Matic Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrls: ['https://polygon-rpc.com/'],
      blockExplorerUrls: ['https://polygonscan.com/']
    }]

  const web3 = new Web3(window.ethereum)

  const network = await web3.eth.net.getId();

  if (network.toString() === '137') {
    alert("Polygon Network has already been added to Metamask.");
    return;
  }

  window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params
  })
  .then(() => console.log('Success'))
  .catch((error) => console.log("Error", error.message));
}

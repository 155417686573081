import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { addToken, addNetwork } from '../utils/metamask';
import AudioPlayer from '../components/AudioPlayer';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as Book } from '../images/book.svg';
import { ReactComponent as GitHub } from '../images/github.svg';
import { ReactComponent as Medium } from '../images/medium.svg';
import { ReactComponent as Telegram } from '../images/telegram.svg';
import { ReactComponent as Twitter } from '../images/twitter.svg';
import { ReactComponent as Youtube } from '../images/youtube.svg';
import { ReactComponent as Polygon } from '../images/polygon.svg';

import audioSrc from '../media/sandman.mp3';

const Sandman = () => {
  useEffect(() => {
    document.body.classList.add('sandman-page')
    const link = document.querySelector("link[rel~='icon']")
    link.href = '/favicon-dream.ico'
  }, [])

  useEffect(() => {
    return () => {
      document.body.classList.remove('sandman-page')
    }
  }, [])

  return (
    <div className="container">
      <div className="row align-items-end">
        <aside className="col-lg-3 d-none d-lg-flex py-5">
          <a href="https://rugdoc.io/project/sandman/" className="position-relative rugdoc" target="_blank" rel="noreferrer">
            <small className="position-absolute end-0 rounded text-light border border-warning px-1 neon">KYC / LOW RISK</small>
            <img src="https://rugdoc.io/assets/2021/06/rugdoc-review-badge-with-glow.png" width="200" />
          </a>
        </aside>
        <main role="main" className="col-lg-6 vh-100 py-5 text-center d-flex flex-column position-relative">
        <div className="position-absolute" style={{ top: '10px', left: '0', right: '0' }}>
            <div className="d-flex align-items-center justify-content-center">
              <span className="text-light me-2">go to:</span>
              <Link to="/sandman" className="btn btn-outline-danger disabled me-1">L1</Link>
              <Link to="/delirium" className="btn btn-outline-warning me-1">L2</Link>
              <Link to="/despair" className="btn btn-outline-secondary me-1">L3</Link>
              <Link to="/destruction" className="btn btn-outline-primary me-1">L4</Link>
              <Link to="/desire" className="btn btn-outline-danger me-1">L5</Link>
              <Link to="/destiny" className="btn btn-outline-light me-1">L6</Link>
              <Link to="/" className="btn btn-outline-primary">L7</Link>
            </div>
          </div>
          <div className="mx-3 mx-lg-5 flex-grow-1 mt-3">
            <Logo />
          </div>
          <div className="mx-lg-9 mb-3">
            <div className="card border-danger shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="morpheus" onClick={addToken}>
                MORPHEUS
              </a>
            </div>
            <div className="card border-danger shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="sandman" onClick={addToken}>
                SANDMAN
              </a>
            </div>
            <div className="card border-danger shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="sandmanMatic" onClick={addToken}>
                SANDMAN-MATIC
              </a>
            </div>
            <div className="card border-danger shadow-card mb-2 bg-transparent">
              <a href="#" className="add-token stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" data-token="sandmanUsdc" onClick={addToken}>
                SANDMAN-USDC
              </a>
            </div>
            <div className="card border-danger shadow-card mb-2 bg-transparent">
              <a href="#" className="stretched-link link-light card-link rounded fs-5 fw-bold px-3 py-1" id="add-network" onClick={addNetwork}>
                <Polygon />
                <span className="ms-2">Add Polygon Network</span>
              </a>
            </div>
          </div>
          <footer className="mb-4 mb-lg-0">
            <AudioPlayer audioSrc={audioSrc} />
            <a className="link-danger mx-2" href="https://github.com/sandmanfinance" target="_blank" rel="noreferrer">
              <GitHub />
            </a>
            <a className="link-danger mx-2" href="https://docs.sandman.farm/" target="_blank" rel="noreferrer">
              <Book />
            </a>
            <a className="link-danger mx-2" href="https://twitter.com/sandman_finance" target="_blank" rel="noreferrer">
              <Twitter />
            </a>
            <a className="link-danger mx-2" href="https://t.me/SandMan_Finance" target="_blank" rel="noreferrer">
              <Telegram />
            </a>
            <a className="link-danger mx-2" href="https://sandmanfinance.medium.com/" target="_blank" rel="noreferrer">
              <Medium />
            </a>
            <a className="link-danger mx-2" href="https://www.youtube.com/channel/UCN_deX0boZPVUre-SV6aqwA" target="_blank" rel="noreferrer">
              <Youtube />
            </a>
          </footer>
          <div className="d-flex d-lg-none justify-content-center">
            <a href="https://rugdoc.io/project/sandman/" target="_blank" rel="noreferrer" className="card-link btn btn-sm btn-outline-light me-1">
              RugDoc
              <span className="ms-1 kyc">KYC</span>/<span className="low-risk">LOW RISK</span>
            </a>
            <a href="https://paladinsec.co/projects/sandman/" className="card-link btn btn-sm btn-outline-light" target="_blank" rel="noreferrer">
              PALADIN AUDITED
            </a>
            {/* <a href="#" className="card-link btn btn-sm btn-outline-light" target="_blank" rel="noreferrer">
              DEFIYIELD AUDITED
            </a> */}
          </div>
        </main>
        <aside className="col-lg-3 d-none d-lg-flex justify-content-end py-5">
          {/* <div class="d-flex flex-column"> */}
            <a href="https://paladinsec.co/projects/sandman/" className="mb-5" target="_blank" rel="noreferrer">
              <img src="https://paladinsec.co/pld/assets/audited-by-paladin-long.svg" width="200" />
            </a>
            {/* <a href="#" className="defiyield px-2 py-1 rounded-3" target="_blank" rel="noreferrer">
              <LogoDefiYield />
            </a> */}
          {/* </div> */}
        </aside>
      </div>
    </div>
  );
}

export default Sandman;
